const BASE_URL = (() => {
  switch (process.env.REACT_APP_NODE_ENV) {
    case 'staging':
      return "https://apip-staging.classplusapp.com/";
    case 'preprod':
      return "https://apip-preprod.classplusapp.com/";
    case 'qa':
      return "https://apip-qa.classplusapp.com/";
    case 'production':
      return "https://api.classplusapp.com/";
    default:
      return "https://apip-staging.classplusapp.com/";
  }
})();

export default BASE_URL;
